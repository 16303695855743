<template>
    <footer>
        <div class="container">
            <div class="f-content d-flex justify-between">
                <div class="f-left">
                    <RouterLink to="/">
                        <div class="f-logo">
                            <img src="@/assets/icons/footer-logo.svg" alt="logo" />
                        </div>
                    </RouterLink>
                    
                    <div class="f-nav d-flex wrap helvetica">
                        <div>
                            <div class="f-nav__item"><RouterLink to="/about"><p>About Us</p></RouterLink></div>
                            <div class="f-nav__item"><RouterLink to="/team"><p>Team</p></RouterLink></div>
                        </div>
                        <div>
                            <div class="f-nav__item"><RouterLink to="/product"><p>Product</p></RouterLink></div>
                            <div class="f-nav__item"><RouterLink to="/contacts"><p>Contacts</p></RouterLink></div>
                        </div>
                        <div class="f-nav__item">
                            <div class="d-flex align-center" @mouseover="isServices = true">
                                <p>Services</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4" viewBox="0 0 6 4" fill="none">
                                  <path opacity="0.72" d="M5 0C5.41202 0 5.64721 0.470382 5.4 0.8L3.4 3.46667C3.2 3.73333 2.8 3.73333 2.6 3.46667L0.6 0.8C0.352786 0.470382 0.587977 0 1 0H5Z" fill="white"/>
                                </svg>
                            </div>
                            <div class="hidden-nav" :class="{'active': isServices}" @mouseleave="isServices = false">
                                <div class="inner d-flex column">
                                    <RouterLink to="/software_development">
                                        <div
                                            class="hidden-nav__item d-flex align-center text-xs"
                                            :class="{ 'active': isRouteActive('/software_development') }"
                                        >Software Development</div>
                                    </RouterLink>
                                    <RouterLink to="/database_management">
                                        <div
                                            class="hidden-nav__item d-flex align-center text-xs"
                                            :class="{ 'active': isRouteActive('/database_management') }"
                                        >Database Management</div>
                                    </RouterLink>
                                    <RouterLink to="/cloud_solutions">
                                        <div
                                            class="hidden-nav__item d-flex align-center text-xs"
                                            :class="{ 'active': isRouteActive('/cloud_solutions') }"
                                        >Cloud Solutions</div>
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <UiForm :title="formTitle ?? ''" :btnText="btnText ?? ''" :fullTitle="full"></UiForm>
            </div>
            <div class="f-bottom helvetica">
                <div class="d-flex align-center">
                    <a href="https://www.linkedin.com/company/lfinspire/about/" target="_blank">LinkedIn</a>
                    <a href="mailto:info@lfinspire.com">info@lfinspire.com</a>
                    <RouterLink to="privacy">Privacy Policy</RouterLink>
                </div>
                <div class="little d-flex align-center">
                    <p>© 2024 LFinspire LTD</p>
                    <div class="dot"></div>
                    <p>Christodoulou Chatzipavlou 199, 4th floor, Flat/Office 4E 3036 Limassol, Cyprus</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
import {ref} from "vue";
import UiForm from "./UiForm.vue";
import { useRoute } from 'vue-router';

const isServices = ref(false);
const route = useRoute();

const isRouteActive = (routePath) => {
  return route.path === routePath;
};

const props = defineProps({
    formTitle: String,
    btnText: String,
    full: Boolean
})
</script>

<style lang="scss" scoped>
footer {
    background: #1B1A21;
    padding: 88px 0 16px;
    color: #fff;
}
.f-logo {
    margin-bottom: 40px;
}
.f-nav {
    max-width: 305px;
    column-gap: 48px;
    &__item {
        position: relative;
        p {
            opacity: 0.72;
        }
        .hidden {
            display: none;
        }
        & + .f-nav__item {
            margin-top: 16px;
        }
        & svg {
            margin-left: 6px;
        }
    }
}
a, a:visited, a:focus {
    color: #fff !important;
    text-decoration: none;
}
.f-bottom {
    opacity: 0.5;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-top: 24px;
    & .d-flex:first-child {
        gap: 24px;
        padding-bottom: 16px;
    }
    .little {
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        gap: 16px;
        padding-top: 16px;
        border-top: 1px solid rgba(255, 255, 255, 0.03);
        .dot {
            width: 3px;
            height: 3px;
            border-radius: 3px;
            opacity: 0.2;
            background: #FFF;
        }
    }
}

@media (max-width: 1200px) {
    .f-nav {
        column-gap: 28px;
        row-gap: 16px;
    }
    .f-content {
        gap: 15px;
    }
}

@media (max-width: 768px) {
    footer {
        padding: 66px 0 20px;
    }
    .f-content {
        flex-direction: column-reverse;
        align-items: center;
        gap: 75px;
    }
    .f-left {
        max-width: 500px;
        width: 100%;
    }
    .f-logo {
        margin-bottom: 30px;
    }
    .f-nav {
        column-gap: 48px;
    }
    .f-bottom {
        margin-top: 34px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 500px;
        .d-flex:first-child {
            padding-bottom: 0;
        }
        .little {
            margin-top: 31px;
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 0px;
            padding-top: 0;
            border-top: none;
            .dot {
                display: none;
            }
            p:first-child {
                opacity: 0.3;
                padding-top: 10px;
            }
            p:nth-child(3) {
                width: 85%;
                padding-bottom: 10px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.03);
            }
        }
    }
}

@media (max-width: 450px) {
    .f-logo {
        width: 142px;
        img {
            width: 100%;
        }
    }
    .f-bottom div:first-child {
        flex-wrap: wrap;
    }
}
</style>