<template>
  <Loader v-if="!isLoaded"></Loader>
  <div class="home color-dark">
    <div class="first" id="block1"
        :class="{'animate': blocks?.[0]?.visible && blocks?.[0]?.cnt == 1, 'hide-block': blocks[0].cnt == 0}"> 
        <MainScreen
          :anim="isLoaded"
          title="Empowering Your"
          title2="Digital Journey"
          button
          isMain
          subTitle="Unleash the power of innovative IT solutions tailored for your business success."
        ></MainScreen>
    </div>
   
    <div class="h-about d-flex column align-center">
      <svg class="svg" v-if="screenWidth > 450" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 91" fill="none">
        <path d="M1.09139e-10 64C1.09139e-10 64 438.823 0 720 0C1001.18 0 1440 64 1440 64V91H0L1.09139e-10 64Z" fill="#F8F8F8"/>
      </svg>
      <svg class="svg" v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 453 70" fill="none">
        <path d="M0 24.7368C0 24.7368 138.046 0 226.5 0C314.954 0 453 24.7368 453 24.7368V70H0L0 24.7368Z" fill="#F8F8F8"/>
      </svg>
      <!-- <div class="h-about__half-rec"></div> -->
      <div class="block-btn"><p>About Us</p></div>
      <div :class="{'mob-anim':screenWidth<=768, 'animate': blocks?.[1]?.visible && blocks?.[1]?.cnt == 1 && isLoaded, 'hide-block': blocks[1].cnt == 0}" id="block2">
        <div class="content d-flex column justify-center" :class="{'animate': blocks?.[1]?.visible && blocks?.[1]?.cnt == 1 && isLoaded}">
          <div class="text-xl uppercase text-center futura">
            <div class="t-desktop" :class="{'anim': screenWidth > 768}" v-if="screenWidth > 768"><p>Crafting IT Excellence</p></div>
            <div class="t-desktop" :class="{'anim': screenWidth > 768}" v-if="screenWidth > 768"><p class="delay">at <span class="color-prime underline">LFinspire</span></p></div>
            <div class="t-mobile" v-if="screenWidth <= 768"><p>Crafting IT Excellence at <span class="color-prime underline">LFinspire</span></p></div>
          </div>
          <div class="text-xs text-center helvetica" :class="{'anim anim--little': screenWidth > 768}"><p>At the intersection of innovation and reliability, we stand, committed to propelling businesses forward. With tailored IT services and deep industry insights, LFINSPIRE is your trusted partner in navigating the digital landscape.</p></div>
          <div class="d-flex align-center justify-center" :class="{'anim': screenWidth > 768}">
            <RouterLink to="/about">
              <Button icon="arrow-ur-circle" colorPrime>Explore More</Button>
            </RouterLink>
            <Button icon="chat" round colorPrime @click="ismodal=true"></Button>
          </div>
        </div>
      </div>
      
      <div class="line"></div>
      <div id="block3" class="h-about__bottom d-flex align-start justify-center" :class="{'anim': screenWidth > 768, 'animate': blocks?.[2]?.visible && blocks?.[2]?.cnt == 1 && isLoaded, 'hide-block': blocks[2].cnt == 0}">
        <p class="text-m uppercase futura">Achievements That Speak Volumes</p>
        <p class="text-xs helvetica">Our track record is a testament to our commitment and expertise. At LFINSPIRE, we don't just deliver IT solutions; we forge lasting relationships and drive tangible results, ensuring our clients thrive in a digital-first world.</p>
      </div>
    </div>
    <div class="h-product d-flex column align-center">
      <div class="h-product__bg">
        <div class="custom-shape"></div>
        <!-- <div class="rec">
          <svg viewBox="0 0 1360 614" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40 0.5H1320C1341.82 0.5 1359.5 18.1848 1359.5 40V573.349C1359.5 597.57 1338.11 616.173 1314.13 612.747C1198.99 596.297 892.048 555.5 680 555.5C467.952 555.5 161.015 596.297 45.8661 612.747C21.8902 616.173 0.5 597.57 0.5 573.349V40C0.5 18.1848 18.1848 0.5 40 0.5Z" stroke="black" stroke-opacity="0.06"/>
          </svg>
          <div class="rec__bg"></div> -->
        <!-- </div> -->
        <!-- <img v-if="screenWidth > 768" :src="require('@/assets/images/second1.svg')" alt="background" />
        <img v-else :src="require('@/assets/images/second.svg')" alt="background" /> -->
        
      </div>
      <div id="block4" :class="{'mob-anim':screenWidth<=768, 'animate': blocks?.[3]?.visible && blocks?.[3]?.cnt == 1 && isLoaded, 'hide-block': blocks[3].cnt == 0}">
        <div class="content2 z-1" :class="{'animate': blocks?.[3]?.visible && blocks?.[3]?.cnt == 1 && isLoaded}">
          <div class="block-btn"><p>Product</p></div>
          <div class="text-xl uppercase text-center futura">
            <div class="" :class="{'anim': screenWidth > 768}"><p>PAY <span class="color-prime underline">MEMO</span>: Simplifying</p></div>
            <div class="" :class="{'anim': screenWidth > 768}"><p class="delay">Customer Data Management</p></div>
          </div>
          <div class="text-xs text-center helvetica" :class="{'anim': screenWidth > 768}">
            <p class="delay little">Introducing PAY MEMO, our flagship software solution, designed to consolidate and streamline customer data for businesses. Experience seamless integration, comprehensive profiles, and a revolution in efficient balance management.</p>
          </div>
          <div class="d-flex align-center justify-center" :class="{'anim': screenWidth > 768}">
            <RouterLink to="/product">
              <Button icon="arrow-ur-circle" colorPrime>Learn More</Button>
            </RouterLink>
            <Button icon="chat" round colorPrime @click="ismodal=true"></Button>
          </div>
        </div>
      </div>
    </div>

    <div class="h-services d-flex column align-center">
      <svg v-if="screenWidth > 450" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 91" fill="none">
        <path d="M1.09139e-10 64C1.09139e-10 64 438.823 0 720 0C1001.18 0 1440 64 1440 64V91H0L1.09139e-10 64Z" fill="#143083"/>
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 453 70" fill="none">
        <path d="M0 24.7368C0 24.7368 138.046 0 226.5 0C314.954 0 453 24.7368 453 24.7368V70H0L0 24.7368Z" fill="#143083"/>
      </svg>
      <div class="h-services__bg"></div>
      <div id="block5" :class="{'mob-anim':screenWidth<=768, 'animate': blocks?.[4]?.visible && blocks?.[4]?.cnt == 1 && isLoaded, 'hide-block': blocks[4].cnt == 0}">
        <div class="content2 z-1" :class="{'animate': blocks?.[4]?.visible && blocks?.[4]?.cnt == 1 && isLoaded}">
          <div class="block-btn second"><p>Services</p></div>
          <div class="text-xl uppercase text-center color-white futura">
            <div class="" :class="{'anim anim2': screenWidth > 768}"><p>Customized IT Solutions for </p></div>
            <div class="" :class="{'anim anim2': screenWidth > 768}"><p>Tomorrow's Challenges</p></div>
          </div>
          <div class="anim anim2 text-xs text-center color-white helvetica"><p>From IT consulting and strategic business process outsourcing to bespoke software solutions, LFINSPIRE offers a suite of services designed to fit your unique needs. Let us be your beacon in the ever-evolving digital landscape, ensuring agility, efficiency and growth.</p></div>
          <div class="swip-anim"><Swiper></Swiper></div>
        </div>
      </div>
    </div>

    <div class="h-team z-1 d-flex column align-center">
      <div class="h-team__bg"></div>
      <div id="block6" :class="{'mob-anim':screenWidth<=768, 'animate': blocks?.[5]?.visible && blocks?.[5]?.cnt == 1 && isLoaded, 'hide-block': blocks[5].cnt == 0}">
        <div class="content z-1" :class="{'animate': blocks?.[5]?.visible && blocks?.[5]?.cnt == 1}">
          <div class="block-btn"><p>Team</p></div>
          <div class="des text-xl uppercase text-center futura" :class="{'anim': screenWidth > 768}"><p>Digital <span class="color-prime underline">Mavericks</span> at </p></div>
          <div class="des text-xl uppercase text-center futura" :class="{'anim': screenWidth > 768}"><p>Your Service</p></div>
          <div class="mobi text-xl uppercase text-center futura" :class="{'anim': screenWidth > 768}"><p>Digital <span class="color-prime underline">Mavericks</span> at Your Service</p></div>
          <div class="text-xs text-center helvetica" :class="{'anim anim2': screenWidth > 768}"><p>At the heart of LFINSPIRE is a dedicated ensemble of IT professionals, driven by passion and bound by a shared vision. Our team not only possesses deep technical expertise but is also committed to fostering collaboration, innovation, and growth. Together, we shape the future, one digital solution at a time.</p></div>
          <div class="" :class="{'anim': screenWidth > 768}">
            <RouterLink to="/team"><Button icon="arrow-ur-circle" colorPrime>Meet our Innovators</Button></RouterLink>
            
          </div>
        </div>
      </div>
    
      <div class="people" v-for="(t, id) in team" :key="id" :class="`people${id+1}`">
        <div class="people__img">
          <img :src="require(`@/assets/images/people/${id+1}.jpg`)" alt="human" />
        </div>
        <p>{{ t }}</p>
      </div>
    </div>

    <Footer full formTitle="Reach out and let's shape the future of IT together." btnText="Send us a Message"></Footer>
  </div>
  <UiModal v-if="ismodal" @close="ismodal=false"></UiModal>
</template>

<script setup>
import {ref, onUnmounted, onMounted, onBeforeUnmount} from "vue";
import Loader from '@/components/Loader.vue';
import MainScreen from "@/components/MainScreen.vue";
import Button from "@/components/Button.vue";
import Swiper from "@/components/Swiper.vue";
import Footer from "@/components/Footer.vue";
import UiModal from "@/components/UiModal.vue";
import {isElementVisible} from "@/visibility";

const ismodal = ref(false);

const screenWidth = ref(window.innerWidth);

const team = ref(['Cloud architect', 'Database analyst', 'IT Support Specialist', 'IT DevOps','Affiliate Manager', 'Machine Learning Engineer']);

const isLoaded = ref(false || localStorage.getItem('loaded'));

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
  window.removeEventListener('scroll', handleScroll);
});
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};

const blocks = ref(Array.from({ length: 8 }, () => ({ id: null, visible: false, cnt: 0 })));

const handleScroll = () => {
  blocks.value.forEach((block) => {
    const element = document.getElementById(block.id);
    if (element) {
      const isVisible = isElementVisible(element, 0);
      if (isVisible && !block.visible) {
        block.visible = true;
        block.cnt++;
      } else if (!isVisible && block.visible) {
        block.visible = false;
      }
    }
  });
};

onMounted(() => {
  blocks.value.forEach((block, index) => {
    if(index == blocks.value.length - 1) block.id = `block14`;
    else block.id = `block${index + 1}`;
  });
  handleScroll()
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', handleResize);
  setTimeout(() => {
    isLoaded.value = true;
    localStorage.setItem('loaded', true);
  }, 1000);
});
</script>

<style lang="scss">
.custom-shape {
  position: relative;
  width: 100%; 
  height: 100%;
  background: url('../assets/images/secondline.png') center/cover no-repeat; 
  // clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 91%, 0 100%);
  clip-path: path('M0.5 40C0.5 18.1848 18.1848 0.5 40 0.5H1320C1341.82 0.5 1359.5 18.1848 1359.5 40V573.349C1359.5 597.57 1338.11 616.173 1314.13 612.747C1198.99 596.297 892.048 555.5 680 555.5C467.952 555.5 161.015 596.297 45.8661 612.747C21.8902 616.173 0.5 597.57 0.5 573.349V40Z');
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 40px;
  overflow: hidden;
}
.home {
  overflow: hidden;
  background: #F8F8F8;
  a {
    text-decoration: none;
  }
}
.h-about {
  background: #F8F8F8;
  position: relative;
  padding-bottom: 88px;
  .svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -70%);
    max-height: 100px;
    width: 101%;
  }
  &__bottom {
    gap: 32px;
    margin: 0 auto;
    padding: 88px 0 0;
    max-width: 830px;
    overflow: hidden;
    &.animate {
      opacity: 0;
      animation: opac 1s forwards;
      &.anim .text-m, &.anim .text-xs {
        transform: translateY(100%);
        animation: fade 1s forwards;
      }
    }
    .text-m {
      width: 40%;
    }
    .text-xs {
      width: 60%;
    }
  }
  .line {
    width: calc(100% - 80px);
    height: 1px;
    margin: 0 40px;
    background: rgba(0,0,0,0.06);
  }
}
.h-product {
  width: 100%;
  // height: 750px;
  position: relative;
  background: url('../assets/images/second-bg.jpg') center/cover no-repeat;
  padding-bottom: 50px;
  &__bg {
    position: absolute;
    width: calc(100% - 58px);
    height: calc(100% - 78px);
    max-width: 1361px;
    margin: 0 auto;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.mob-anim.animate{
  overflow: hidden;
  animation: opac 1s forwards;
  .content, .content2{
    transform: translateY(100%);
    opacity: 0;
    animation: fade 2.5s forwards;
  }
}
.content {
  max-width: 780px;
  margin: 0 auto;
  padding: 16px 0 88px;
  position: relative;
  .text-xs {
    margin: 16px 70px 32px;
  }
  &.animate {
    opacity: 0;
    animation: opac 1s forwards;
  }
  &.animate .anim {
    overflow: hidden;
    p, .button {
      transform: translateY(100%);
      animation: fade 0.8s forwards;
      opacity: 0;
      &.delay {
        animation-delay: 0.1s;
      }
    }
    .button {
      transform: translateY(150%);
    }
    &--little {
      animation-delay: 0.4s;
      animation: fade 0.9s forwards;
    }
    &.t-mobile {
      display: none;
    }
  }
}
.content2 {
  padding: 115px 0;
  max-width: 1000px;
  margin: 0 auto;
  .text-xl {
    margin: 16px 0;
  }
  .text-xs.text-center {
    margin: 0 240px 32px;
  }
  &.animate {
    opacity: 0;
    animation: opac 1s forwards;
    .swip-anim {
      opacity: 0;
      animation: opac 1s forwards;
      animation-delay: 1s;
    }
  }
  &.animate .anim {
    overflow: hidden;
    p:not(.slider), .button:not(.slider) {
      transform: translateY(100%);
      animation: fade 0.7s forwards;
      animation-delay: 1s;
      opacity: 0;
      &.delay {
        animation-delay: 1.1s;
        &.little {
          transform: translateY(150%);
          animation: fade 0.9s forwards;
          animation-delay: 1.2s;
        }
      }
    }
    .button:not(.slider) {
      transform: translateY(150%);
    }
  }
}
.anim2 {
  p, .button {
    animation: fade 1s forwards;
  }
}
.h-services {
  position: relative;
  width: 100%;
  height:100%;
  .content2 {
    padding: 0 0 40px;
  }
  svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -70%);
    width: 105%;
    max-height: 100px;
  }
  &__bg {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url('../assets/images/blue-bg.jpg') center/cover no-repeat;
  }
}
.h-team {
  width: 100%;
  height: 100%;
  background: url('../assets/images/second-bg.jpg') center/cover no-repeat;
  padding-top:53px;
  overflow: hidden;
  .block-btn {
    margin-bottom: 16px;
  }
  .mobi {
    display: none;
  }
  a {
    margin-bottom: 16px;
  }
  .button {
    margin: 0 auto;
  }
  .people1 {
    top: -30px;
    left: 6%;
  }
  .people2 {
    top: 38%;
    left: -35px;
  }
  .people3 {
    bottom: 16px;
    left: 16%;
  }
  .people4 {
    top: 35%;
    right: 10%;
  }
  .people5 {
    top: -24px;
    right: -20px;
  }
  .people6 {
    bottom: 8px;
    right: -100px;
  }
}

@media (max-width: 1200px) {
  .h-about {
    &__bottom {
      margin: 0 60px;
    }
  }
  .h-team {
    .people4 {
      right: 10px;
    }
  }
  .h-product{ 
    background: url('../assets/images/second-bg2.png') center/cover no-repeat;
    &__bg{
      // height: calc(100% - 80px);
      .custom-shape {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 94%, 0 100%);
      }
    }
  }
  .content .text-xs {
    margin: 16px 120px 32px;
  }
}

@media (max-width: 768px) {
  .content {
    margin: 0 20px;
    max-width: 500px;
    padding: 16px 24px 66px;
    .anim {
      &.t-desktop {
        display: none;
      }
      &.t-mobile {
        display: block;
        width: 95%;
        max-width: 500px;
        margin: 0 auto;
      }
      .button:first-child {
        width: 100%;
      }
    }
    .text-xs {
      margin: 16px 0 26px;
    }
  }
  .content2 {
    margin: 0 20px;
    max-width: 500px;
    padding: 36px 24px 66px;
    .text-xs.text-center {
      margin: 0 auto;
      max-width: 500px;
      & + div.d-flex {
        margin-top: 26px;
        .button:first-child {
          width: 100%;
        }
      }
    }
  }
  .h-about {
    padding-bottom: 66px;
    &__bottom {
      gap: 16px;
      padding: 66px 24px 0;
      flex-direction: column;
      margin: 0 20px;
      max-width: 500px;
      text-align: center;
      .text-m {
        width: 100%;
      }
      .text-xs {
        width: calc(100% - 40px);
        margin: 0 auto;
      }
    }
  }
  .h-services .mob-anim.animate {
    overflow: visible;
  }
  .h-product {
    &__bg {
      width: calc(100% - 40px);
      height: calc(100% - 30px);
      top: 0;
      .custom-shape {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 97%, 0 100%);
      }
    }
  }
  .h-team {
    padding-top: 50px;
    .mobi {
      display: block;
    }
    .des {
      display: none;
    }
    .content {
      max-width: 450px;
      padding: 16px 60px 66px;
    }
    .people1 {
      top: -10px;
      .people__img {
        font-size: 80px !important;
        img {
          object-position: -24px -12px;
        }
      }
    }
    .people2 {
      left: -30px;
      .people__img {
        font-size: 90px !important;
        img {
          object-position: -23px 0;
        }
      }
    }
    .people3 {
      left: -10px;
      .people__img {
        font-size: 80px !important;
      }
    }
    .people4 {
      right: -10px;
    }
    .people5{
      top: -6px;
    }
    .people6 {
      max-width: 116px;
      right: 0;
    }
  }
}

@media (max-width: 450px) {
  .h-product {
    &__bg {
      height: calc(100% - 45px);
      .custom-shape {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 98%, 0 100%);
      }
    }
    .content2 {
      padding: 36px 24px 84px;
    }
  }
  .content2 .text-xs.text-center {
    max-width: 262px;
  }
  .h-team {
    .content {
      padding: 16px 24px 66px;
    }
    .people1, .people3 {
      .people__img {
        font-size: 66px !important;
      }
    }
    .people1 {
      left: 0;
      .people__img img {
        object-position: -19px -2px;
      }
    }
    .people3 {
      bottom: 37px;
      left: -42px;
      .people__img img {
        object-position: -4px -19px;
      }
    }
    .people2 {
      top: 30%;
      left: -48px;
      .people__img {
        font-size:82px !important;
      }
    }
    .people4 {
      right: -25px;
      top: 38%;
      p {
        width: 64px;
      }
    }
    .people6 {
      bottom: -15px;
      right: -11px;
      .people__img img {
        object-position: 2px -20px;
      }
      p {
        width: 64px;
      }
    }
  }
}
</style>