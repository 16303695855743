<template>
    <div class="head-hide" v-if="isFixed"></div>
    <header>
        <div class="head" :class="{'fixed': isFixed}">
            <div class="wrapper d-flex align-center">
                <RouterLink to="/">
                    <div class="logo">
                        <img :src="require('@/assets/icons/logo.svg')" alt="logo" />
                    </div>
                </RouterLink>
        
                <div class="t-content d-flex align-center justify-between">
                    <RouterLink to="/" class="mob">
                        <div class="logo">
                            <img :src="require('@/assets/icons/logo.svg')" alt="logo" />
                        </div>
                    </RouterLink>
                    <nav class="d-flex align-center justify-between semibold">
                        <RouterLink to="/about">
                            <div class="item">
                                <div class="item__title" :class="{ 'active-link': isRouteActive('/about') }">About Us</div>
                            </div>
                        </RouterLink>
                        <RouterLink to="/product">
                            <div class="item">
                                <div class="item__title" :class="{ 'active-link': isRouteActive('/product') }">Product</div>
                            </div>
                        </RouterLink>
                        <div class="item">
                            <div class="item__title d-flex align-center" @mouseover="isServices = true">
                                Services
                                <svg viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 2C5.41202 2 5.64721 2.47038 5.4 2.8L3.4 5.46667C3.2 5.73333 2.8 5.73333 2.6 5.46667L0.6 2.8C0.352786 2.47038 0.587977 2 1 2H5Z" fill="white"/>
                                </svg>
                            </div>
                            <div class="hidden-nav" :class="{'active': isServices}" @mouseleave="isServices = false">
                                <div class="inner d-flex column">
                                    <RouterLink to="/software_development">
                                        <div
                                            class="hidden-nav__item d-flex align-center text-xs"
                                            :class="{ 'active': isRouteActive('/software_development') }"
                                        >Software Development</div>
                                    </RouterLink>
                                    <RouterLink to="/database_management">
                                        <div
                                            class="hidden-nav__item d-flex align-center text-xs"
                                            :class="{ 'active': isRouteActive('/database_management') }"
                                        >Database Management</div>
                                    </RouterLink>
                                    <RouterLink to="/cloud_solutions">
                                        <div
                                            class="hidden-nav__item d-flex align-center text-xs"
                                            :class="{ 'active': isRouteActive('/cloud_solutions') }"
                                        >Cloud Solutions</div>
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                        <RouterLink to="/team">
                            <div class="item">
                                <div class="item__title" :class="{ 'active-link': isRouteActive('/team') }">Team</div>
                            </div>
                        </RouterLink>
                        <RouterLink to="/contacts">
                            <div class="item">
                                <div class="item__title" :class="{ 'active-link': isRouteActive('/contacts') }">Contacts</div>
                            </div>
                        </RouterLink>
                    </nav>
                    <div class="actions d-flex align-center">
                        <div class="menu" @click="isMenu = true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g clip-path="url(#clip0_200_4455)">
                                    <path d="M4.0587 8.32873C6.27057 8.32873 8.06992 6.52935 8.06992 4.31751C8.06992 2.10211 6.27057 0.302734 4.0587 0.302734C1.8433 0.302734 0.0439453 2.10211 0.0439453 4.31751C0.0439453 6.52935 1.8433 8.32873 4.0587 8.32873Z" fill="white"/>
                                    <path d="M15.9419 0.302734C13.73 0.302734 11.9307 2.10211 11.9307 4.31751C11.9307 6.52935 13.73 8.32873 15.9419 8.32873C18.1573 8.32873 19.9566 6.52935 19.9566 4.31751C19.9566 2.10211 18.1573 0.302734 15.9419 0.302734Z" fill="white"/>
                                    <path d="M4.0587 19.6959C6.27057 19.6959 8.06992 17.8965 8.06992 15.6811C8.06992 13.4693 6.27057 11.6699 4.0587 11.6699C1.8433 11.6699 0.0439453 13.4693 0.0439453 15.6811C0.0439453 17.8965 1.8433 19.6959 4.0587 19.6959Z" fill="white"/>
                                    <path d="M15.9419 11.6699C13.73 11.6699 11.9307 13.4693 11.9307 15.6811C11.9307 17.8965 13.73 19.6959 15.9419 19.6959C18.1573 19.6959 19.9566 17.8965 19.9566 15.6811C19.9566 13.4693 18.1573 11.6699 15.9419 11.6699Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_200_4455">
                                    <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <a href="https://www.linkedin.com/company/lfinspire/about/" target="_blank">
                            <Button round icon="linkedin"></Button>
                        </a>
                        <Button icon="chat" @click="isModal = true">Contact Us</Button>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div class="menu_modal" v-if="isMenu">
        <div class="menu_modal__content">
            <div class="menu_modal__head d-flex align-center justify-between">
                <RouterLink to="/">
                    <div class="logo logo-mob">
                        <img :src="require('@/assets/icons/logo.svg')" alt="logo" />
                    </div>
                </RouterLink>
                <Button icon="chat" @click="isModal = true">Contact Us</Button>
            </div>
            <div class="d-flex column urls futura-bold">
                <RouterLink to="/" :class="{ 'active-link': isRouteActive('/') }">Home</RouterLink>
                <RouterLink to="/about" :class="{ 'active-link': isRouteActive('/about') }">About us</RouterLink>
                <RouterLink to="/product" :class="{ 'active-link': isRouteActive('/product') }">Products</RouterLink>
                <div class="drop">
                    <p :class="{'active-link': isRouteActive('/software_development') || isRouteActive('/database_management') || isRouteActive('/cloud_solutions') }">SERVICES</p>
                    <RouterLink to="/software_development" :class="{ 'active-link': isRouteActive('/software_development') }">Software Development</RouterLink>
                    <RouterLink to="/database_management" :class="{ 'active-link': isRouteActive('/database_management') }">Database Management</RouterLink>
                    <RouterLink to="/cloud_solutions" :class="{ 'active-link': isRouteActive('/cloud_solutions') }">Cloud Solutions</RouterLink>
                </div>
                <RouterLink to="/team" :class="{ 'active-link': isRouteActive('/team') }">TEAM</RouterLink>
                <RouterLink to="/contacts" :class="{ 'active-link': isRouteActive('/contacts') }">CONTACTS</RouterLink>
                <svg style="cursor: pointer;" @click="isMenu = false" xmlns="http://www.w3.org/2000/svg" width="44" height="43" viewBox="0 0 44 43" fill="none">
                <path d="M11.8126 31.6874C11.0625 30.9372 11.0625 29.7209 11.8126 28.9707L29.4707 11.3126C30.2209 10.5625 31.4372 10.5625 32.1874 11.3126C32.9375 12.0628 32.9375 13.2791 32.1874 14.0293L14.5293 31.6874C13.7791 32.4375 12.5628 32.4375 11.8126 31.6874Z" fill="white"/>
                <path d="M32.1874 31.6874C32.9375 30.9372 32.9375 29.7209 32.1874 28.9707L14.5293 11.3126C13.7791 10.5625 12.5628 10.5625 11.8126 11.3126C11.0625 12.0628 11.0625 13.2791 11.8126 14.0293L29.4707 31.6874C30.2209 32.4375 31.4372 32.4375 32.1874 31.6874Z" fill="white"/>
                </svg>
            </div>
            <div class="menu_modal__foot helvetica d-flex align-center justify-center">
                <a href="https://www.linkedin.com/company/lfinspire/about/" target="_blank">LinkedIn</a>
                <a href="mailto:info@lfinspire.com">info@lfinspire.com</a>
                <RouterLink to="/privacy">Privacy Policy</RouterLink>
            </div>
        </div>
    </div>
    <UiModal v-if="isModal" @close="isModal = false" ></UiModal>
</template>

<script setup>
import {ref, watch, onMounted, onUnmounted} from "vue";
import Button from './Button.vue';
import UiModal from "./UiModal.vue";
import { useRoute } from 'vue-router';

const isServices = ref(false);
const route = useRoute();

const isFixed = ref(false);
const headerHeight = 0;

const isMenu = ref(false);
const isModal = ref(false);

const isRouteActive = (routePath) => {
  return route.path === routePath;
};

const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    isFixed.value = scrollTop > headerHeight;
}
onMounted(() => {
    window.addEventListener('scroll', handleScroll);
})
onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
})

</script>

<style lang="scss" scoped>
header {
    position: relative;
    z-index: 3;
    font-family: 'Helvetica Semi', sans-serif;
}
.head{
    &-hide {
        height: 107px;
    }
    &.fixed {
        background: rgba(26, 61, 168, 0.72);
        backdrop-filter: blur(32px);
        position: fixed;
        top: 0;
        width: 100%;
    }
}
.wrapper {
    width: 100%;
    max-width: 1440px;
    padding: 24px 40px;
    margin: 0 auto;
}
.t-content {
    margin-left: 24px;
    padding: 4px 4px 4px 32px;
    width: 100%;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    color: #fff;
    // backdrop-filter: blur(6px);
    .mob {
        display: none;
    }
}
nav {
    padding: 16px 0;
    gap: 40px;
}
.menu {
    display: none;
}
.logo {
    width: 1em;
    height: 1em;
    font-size: 48px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
a, a:visited, a:focus {
    color: #fff !important;
    text-decoration: none;
}
.item {
    position: relative;
    z-index: 2;
    &__title {
        opacity: 0.8;
        transition: all .3s ease;
        &:hover {
            cursor: pointer;
            color: #C5D86D;
            svg path {
                fill: #C5D86D;
            }
        }
        svg {
            width: 1em;
            height: 1em;
            font-size: 6px;
            margin-left: 6px;
        }
        &.active-link {
            color: #C5D86D;
            opacity: 1;
            font-weight: 700;
            svg path {
                fill: #C5D86D;
            }
        }
    }
}
.menu_modal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    background: url('../assets/images/menu_bg.jpg') center/cover no-repeat;
    color: #fff;
    &__content {
        padding: 19px 21px 30px;
    }
    &__head {
        border-radius: 40px;
        border: 1px solid rgba(255, 255, 255, 0.10);
        backdrop-filter: blur(6px);
        padding: 10px 10px 10px 18px;
    }
    &__foot {
        opacity: 0.5;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        flex-wrap: wrap;
        gap: 24px;
    }
    .urls {
        margin: 64px 0 50px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        text-transform: uppercase;
        gap: 44px;
        text-align: center;
        .active-link {
            color: #CEDE84 !important;
        }
        svg{
            margin: 0 auto;
        }
        .drop {
            a {
                font-family: 'Helvetica Semi', sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 32.2px;
                display: block;
                text-transform: capitalize;
                &.active-link {
                    color: #D6E398 !important;
                }
            }
            p {
                margin-bottom: 20px;
            }
            a + a {
                margin-top: 16px;
            }
        }
    }
}

@media (max-width: 1200px) {
    .wrapper {
        padding: 24px !important;
    }
    nav {
        gap: 24px;
    }
    .t-content {
        padding: 4px 4px 4px 12px;
        justify-content: flex-start;
        .actions {
            margin-left: auto;
        }
    }
}

@media (max-width: 768px) {
    nav {
        display: none;
    }
    .actions {
        margin-left: auto;
        .button.round {
            display: none;
        }
    }
    .menu {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 20px;
    }
    .logo {
        display: none;
    }
    .logo-mob {
        display: block !important;
    }
    .t-content {
        margin-left: 0;
        padding: 10px 10px 10px 18px;
        & .mob {
            display: block !important;
        }
    }
    .t-content .logo {
        display: block;
        font-size: 32px;
    }
    .menu_modal {
        overflow-y: scroll;
    }
}
@media (max-width: 450px) {
    .wrapper {
        padding: 18px 20px !important;
    }
}
</style>