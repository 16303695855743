<template>
    <div class="main-view">
        <div class="bg">
            <img :src="!bgSecond ? require('@/assets/images/main-bg.jpg') : require('@/assets/images/main-bg-sec.jpg')" alt="background image" />
        </div>
        <Header></Header>
        <div class="main-screen" :class="{'prime': isMain}">
            <div class="main-screen__container">
                <div class="m-content" :class="{'prime': isMain, 'full': full, 'width': bgSecond, 'animate': anim}">
                    <div class="m-content-wrap" v-if="isMain">
                        <svg v-if="screenWidth > 768" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1360 534" fill="none" preserveAspectRatio="none" >
                            <path d="M0.5 40C0.5 18.1848 18.1848 0.5 40 0.5H1320C1341.82 0.5 1359.5 18.1848 1359.5 40V493.349C1359.5 517.57 1338.11 536.173 1314.13 532.747C1198.99 516.297 892.048 475.5 680 475.5C467.952 475.5 161.015 516.297 45.8661 532.747C21.8902 536.173 0.5 517.57 0.5 493.349V40Z" stroke="white" stroke-opacity="0.1"/>
                        </svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 546" fill="none" preserveAspectRatio="none">
                            <path d="M20 0.5H330C340.77 0.5 349.5 9.23045 349.5 20V525.629C349.5 537.926 338.286 547.22 326.165 545.092C290.75 538.874 223.321 528.278 175 528.278C126.679 528.278 59.2498 538.874 23.8354 545.092C11.7142 547.22 0.5 537.926 0.5 525.629V20C0.5 9.23046 9.23045 0.5 20 0.5Z" stroke="white" stroke-opacity="0.1"/>
                        </svg>
                    </div>
                    <div class="m-content__body z-1" :class="{'service': isService}">
                        <div class="text">
                            <div class="desk uppercase futura-bold bold" :class="{'prime': isMain, 'anim': screenWidth > 768}"><p class="text-xxl">{{ title ?? '' }}</p></div>
                            <div v-if="title2 !== ''" class="desk uppercase futura bold" :class="{'prime': isMain, 'anim': screenWidth > 768}"><p class="delay text-xxl">{{ title2 ?? '' }}</p></div>
                            <div class="mob uppercase futura-bold" :class="{'prime': isMain, 'anim': screenWidth > 768}">
                                <p class="text-xxl">{{ title ?? '' }} {{ title2 ?? '' }}</p>
                            </div>
                            <template v-if="!isContacts">
                                <div class="mobile text-s main helvetica">
                                    <p>{{ subTitle ?? '' }}</p>
                                </div>
                            </template>
                            <Button :class="{'anim':screenWidth>768}" v-if="button" icon="arrow-ur" colorPrime @click="ismodal=true">Reach Out</Button>
                        </div>
                        <template v-if="!isContacts">
                            <div class="desktop text-s main helvetica">
                                <p>{{ subTitle ?? '' }}</p>
                            </div>
                        </template>
                        <template v-else>
                            <div class="d-flex contacts" :class="{'anim': screenWidth > 768}">
                                <div class="d-flex contacts__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.69 18.933L9.693 18.934C9.89 19.02 10 19 10 19C10 19 10.11 19.02 10.308 18.934L10.31 18.933L10.316 18.93L10.334 18.922C10.4289 18.8779 10.5226 18.8312 10.615 18.782C10.801 18.686 11.061 18.542 11.372 18.349C11.992 17.965 12.817 17.383 13.646 16.584C15.302 14.988 17 12.493 17 9C17 8.08075 16.8189 7.1705 16.4672 6.32122C16.1154 5.47194 15.5998 4.70026 14.9497 4.05025C14.2997 3.40024 13.5281 2.88463 12.6788 2.53284C11.8295 2.18106 10.9193 2 10 2C9.08075 2 8.1705 2.18106 7.32122 2.53284C6.47194 2.88463 5.70026 3.40024 5.05025 4.05025C4.40024 4.70026 3.88463 5.47194 3.53284 6.32122C3.18106 7.1705 3 8.08075 3 9C3 12.492 4.698 14.988 6.355 16.584C7.04875 17.2503 7.8106 17.8419 8.628 18.349C8.94459 18.5456 9.27029 18.7271 9.604 18.893L9.666 18.922L9.684 18.93L9.69 18.933ZM10 11.25C10.5967 11.25 11.169 11.0129 11.591 10.591C12.0129 10.169 12.25 9.59674 12.25 9C12.25 8.40326 12.0129 7.83097 11.591 7.40901C11.169 6.98705 10.5967 6.75 10 6.75C9.40326 6.75 8.83097 6.98705 8.40901 7.40901C7.98705 7.83097 7.75 8.40326 7.75 9C7.75 9.59674 7.98705 10.169 8.40901 10.591C8.83097 11.0129 9.40326 11.25 10 11.25Z" fill="#C5D86D"/>
                                    </svg>
                                    <p class="helvetica text-s">32 Spyrou Kyprianou Avenue, Vashiotis John Marie, 4043 Limassol, Cyprus</p>
                                </div>
                                <div class="d-flex contacts__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M3 4C2.46957 4 1.96086 4.21071 1.58579 4.58579C1.21071 4.96086 1 5.46957 1 6V7.161L9.441 11.382C9.61456 11.4688 9.80595 11.514 10 11.514C10.194 11.514 10.3854 11.4688 10.559 11.382L19 7.162V6C19 5.46957 18.7893 4.96086 18.4142 4.58579C18.0391 4.21071 17.5304 4 17 4H3Z" fill="#C5D86D"/>
                                        <path d="M19 8.83984L11.23 12.7248C10.8481 12.9158 10.427 13.0153 10 13.0153C9.57301 13.0153 9.1519 12.9158 8.77 12.7248L1 8.83984V14.0008C1 14.5313 1.21071 15.04 1.58579 15.4151C1.96086 15.7901 2.46957 16.0008 3 16.0008H17C17.5304 16.0008 18.0391 15.7901 18.4142 15.4151C18.7893 15.04 19 14.5313 19 14.0008V8.83984Z" fill="#C5D86D"/>
                                    </svg>
                                    <p class="helvetica text-s"><a>info@lfinspire.com</a></p>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="m-content__btn" v-if="isMain" @click="goBottom">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M5.33301 12L7.99967 14.6667L10.6663 12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 1.33398V14.6673" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <UiModal v-if="ismodal" @close="ismodal = false"></UiModal>
</template>

<script setup>
import {ref, onMounted, onBeforeUnmount} from "vue";
import Header from './Header.vue';
import Button from './Button.vue';
import UiModal from './UiModal.vue';
import Icon from "./Icon.vue";
const ismodal = ref(false);
const props = defineProps({
    title: String,
    title2: String,
    button: Boolean,
    subTitle: String,
    isMain: Boolean,
    bgSecond: Boolean,
    full: Boolean,
    isContacts: Boolean,
    anim: Boolean,
    isService: Boolean
})

const screenWidth = ref(window.innerWidth);
const handleResize = () => {
  screenWidth.value = window.innerWidth;
};
const goBottom = () => {
    const documentHeight = document.documentElement.scrollHeight;
    window.scrollTo({
        top: documentHeight,
        behavior: 'smooth',
    });
}
onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
.main-view {
    position: relative;
    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        // max-height: 680px;
        top: 0;
        left: 0;
        z-index: 0;
        img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        }
    }
}
.main-screen {
    background: #F8F8F8;
    &.prime {
        padding-bottom: 50px;
        .main-screen__container {
            max-width: 1440px;
            padding: 0 40px;
        }
    }
    &__container {
        width: 100%;
        max-width: 1220px;
        margin: 0 auto;
        padding: 0 40px;
    }
}
.m-content {
    position: relative;
    color: #fff;
    width: 80%;
    opacity: 0;
    &.width {
        width: 85%;
    }
    &.full {
        width: 95%;
        .text-s {
            width: 60%;
        }
    }
    &.prime {
        width: 100%;
        & .m-content__body {
            padding: 104px 70px 158px 72px;
        }
    }
    &.animate {
        animation: opac 0.5s forwards;
        .text-s.main {
            overflow: hidden;
            width: 70%;
            p {
                transform: translateY(100%);
                animation: fade 0.7s forwards;
                animation-delay: 0.5s;
            }
        }
        .text .anim {
            overflow: hidden;
            p {
                transform: translateY(100%);
                animation: fade 0.7s forwards;
                opacity: 0.3;
                &.delay {
                    animation-delay: 0.15s;
                }
            }
        }
        .text {
            overflow: hidden;
            .anim.prime {
                display: inline-flex;
            }
            .button {
                &.anim {
                    animation: fadebtn 0.8s forwards;
                    animation-delay: 0.4s;
                }
            }
        }
        .contacts {
            &.anim {
                overflow: hidden;
                div {
                    transform: translateY(100%);
                    opacity: 0;
                    animation: fade 1s forwards;
                }
            }
        }
    }
    &-wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    &__body {
        padding: 80px 0 88px;
        .mobile {
            display: none;
        }
        &.service {
            padding: 80px 0 128px;
        }
    }
    &__btn {
        position: absolute;
        background: #fff;
        border-radius: 100%;
        bottom: 32px;
        right: 16px;
        padding: 20px;
        width: 56px;
        height: 56px;
        z-index: 2;
        svg path{
            stroke:#4169E1;
        }
        &:hover {
            cursor: pointer;
            svg path {
                stroke: #1B1A21;
            }
        }
    }
    .text {
        margin-bottom: 24px;
        overflow: hidden;
        .anim.prime {
            display: inline-flex;
        }
        .mob {
            display: none !important;
        }
        .button {
            display: inline-flex;
            transform: translate(7px, 100%);
        }
    }
}
.contacts {
    justify-items: flex-start;
    gap: 40px;
    &__item {
        svg {
            margin: 6px 8px 0 0;
            min-width: 20px;
        }
        &:first-child {
            width: 325px;
        }
    }
}

@media (max-width: 1200px) {
    .main-screen__container {
        padding: 0 24px !important;
    }
    .m-content {
        .text {
            margin-bottom: 0;
            div p {
                display: inline-block;
            }
        }
        &__body {
            padding: 80px 20px 80px 40px !important;
        }
    }
}   

@media (max-width: 768px) {
    .m-content {
        width: 100%;
        &.prime {
            padding-bottom: 93px;
            .m-content__body {
                padding: 69px 0 30px !important;
            }
        }
        &.width {
            width: 100%;
        }
        &.full {
            width: 100%;
        }
        &.full .text-s {
            width: 100%;
        }
        &-wrap {
            height: 90%;
        }
        &.animate .text {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .desk {
                display: none !important;
            }
            .mob {
                display: block !important;
                text-align: center;
            }
            .button {
                transform: translate(0, 0);
                margin: 0 auto;
                // animation: fade 1s forwards;
                // transform: translateY(100%);
                // animation-delay: 0.6s;
            }
        }
        &__btn {
            opacity: 0;
            animation: show 1s forwards;
            animation-delay: 1s;
        }
        &__body {
            padding: 69px 30px 30px 30px !important;
            overflow-y: hidden;
            .text {
                transform: translateY(100%);
                animation: fade 1.6s forwards;
                opacity: 0;
            }
            .mobile {
                display: block;
                text-align: center;
                margin: 22px auto 36px;
                max-width: 500px;
            }
            .desktop {
                display: none;
            }
            &.service {
                padding: 69px 30px 56px 30px !important;
            }
        }
        &__btn {
            position: relative;
            bottom: 0;
            right: 0;
            margin: 6px auto 0;
            width: 46px;
            height: 46px;
            padding: 15px;
        }
    }
    .main-screen.prime {
        padding-bottom: 0;
    }
    .contacts {
        margin-top: 22px;
        padding-bottom: 64px;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: center;
        &__item {
            width: 100% !important;
            margin: 0 auto;
            justify-content: center;
            .text-s {
                font-size: 20px;
                line-height: 32px;
            }
        }
    }
}

@media (max-width: 450px) {
    .main-screen__container {
        padding: 0 20px !important;
    }
    .m-content {
        &__body {
            // padding: 20px 0 0 0 !important;
            &.service {
                padding: 69px 0 40px 0 !important;
            }
            .mobile {
                max-width: 262px;
            }
        }
        .text .button {
            margin: 0 16px;
        }
    }
}

@media (max-width: 350px) {
    .m-content .text div .text-xxl {
        font-size: 36px;
        line-height: 50px;
    }
}
</style>