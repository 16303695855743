import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: function () {
      return import('../views/AboutView.vue')
    }
  },
  {
    path: '/product',
    name: 'product',
    component: function () {
      return import('../views/ProductView.vue')
    }
  },
  {
    path: '/team',
    name: 'team',
    component: function () {
      return import('../views/TeamView.vue')
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: function () {
      return import('../views/ContactsView.vue')
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: function () {
      return import('../views/PrivacyView.vue')
    }
  },
  {
    path: '/software_development',
    name: 'software_development',
    component: function () {
      return import('../views/SoftwareView.vue')
    }
  },
  {
    path: '/database_management',
    name: 'database_management',
    component: function () {
      return import('../views/DatabaseView.vue')
    } 
  },
  {
    path: '/cloud_solutions',
    name: 'cloud_solutions',
    component: function () {
      return import('../views/CloudView.vue')
    } 
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.afterEach((to, from) => {
  window.scrollTo(0, 0);
})


export default router
