<template>
    <div class="ui-form">
        <p class="ui-form__title futura-bold" :class="{'full': fullTitle}">{{ title ?? '' }}</p>
        <form id="form">
            <div class="d-flex inputs">
                <div class="d-flex align-center input" :class="{'error': isError && data.name.toString().trim().length == 0}">
                    <input
                        class="jetbrains"
                        type="text" 
                        placeholder="Name" 
                        @input="handleInput($event, 'name')" 
                        v-model="data.name" 
                        required 
                        aria-required="true" 
                        maxlength="100"
                    />
                    <svg v-if="isError && data.name.toString().trim().length == 0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" fill="#E53737"/>
                    </svg>
                </div>
                <div class="input d-flex align-center" :class="{'error': isError && isError && !emailRegex.test(data.email)}">
                    <input
                        class="jetbrains"
                        type="email" 
                        placeholder="Email"
                        v-model="data.email"
                        required
                        maxlength="250"
                    />
                    <svg v-if="isError && isError && !emailRegex.test(data.email)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" fill="#E53737"/>
                    </svg>
                </div>
            </div>
            <input class="mid-input input jetbrains" type="text" placeholder="Message" v-model="data.message"/>
            <Button icon="jet" @click="send" form>{{ btnText ?? '' }}</Button>
        </form>
    </div>
    <UiModal v-if="sent" @close="close" called :success="success" :key="sent"></UiModal>
</template>

<script setup>
import {ref} from "vue";
import Button from "./Button.vue";
import UiModal from "./UiModal.vue";

const props = defineProps({
    title: String,
    btnText: String,
    fullTitle: Boolean
})

const isError = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
const sent = ref(false);
const success = ref(false);

const data = ref({
    name: "",
    email: "",
    message: ""
})

const validate = () => {
    let error = false;
    Object.entries(data.value).map(([key, value]) => {
        if(key == 'name' && value.toString().trim().length == 0) error = true;
        if(key === 'email' && !emailRegex.value.test(value)) error = true;
    });
    return error;
}
const handleInput = (event,propertyName) => {
    data.value[propertyName] = event.target.value;
    if(isError.value) validate();
}
const resetForm = () => {
    data.value = {
        name: "",
        email: "",
        message: ""
    }
}
const fetchData = (formdata) => {
    const request = {
        method: 'POST',
        body: formdata,
        mode: 'no-cors',
    }

    fetch('https://license.darmius.kz/mailsend', request)
    .then(response => {
        sent.value = true;
        success.value = true;
        resetForm();
    })
    .catch(e => {
        sent.value = true;
        success.value = false;
        resetForm();
    })
}

const send = () => {
    let form = document.getElementById('form');
    isError.value = validate();
    form.reportValidity();
    if(!isError.value) {
        const formdata = new FormData();
        formdata.append('id', 'falkoned');
        formdata.append('name', data.value.name);
        formdata.append('email', data.value.email);
        formdata.append('emailto', "info@lfinspire.com");
        formdata.append('message', data.value.message);

        fetchData(formdata)
    }
}

const close = () => {
    resetForm();
    sent.value = false;
    success.value = false;
}
</script>

<style lang="scss" scoped>
.ui-form {
    max-width: 452px;
    &__title {
        color: #FFF;
        font-family: 'Futura', sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 26px;
        width: 90%;
        &.full {
            width: 100%;
        }
    }
    form {
        & .inputs {
            gap: 8px;
        }
        & .mid-input {
            margin: 8px 0;
        }
    }
    .button {
        border-radius: 14px;
        border: 0.5px solid rgba(0, 0, 0, 0.12);
        box-shadow: 0px 9px 10px -8px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.08);
        color: #000;
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .ui-form {
        max-width: 400px;
        &__title {
            font-size: 26px;
        }
    }
}

@media (max-width: 768px) {
    .ui-form {
        max-width: 500px;
        width: 95%;
        .button {
            font-size: 16px;
        }
    }
}

@media (max-width: 450px) {
    .ui-form {
        max-width: 350px;
        width: 95%;
        &__title {
            margin-bottom: 16px;
            font-size: 22px;
            line-height: 32px;
            width: 100%;
        }
        form .inputs {
            gap: 6px;
        }
    }
}
</style>