<template>
  <div class="app">
    <router-view/>
  </div>
</template>
<script>
function clearLocalStorage() {
  localStorage.clear();
}

window.addEventListener('unload', clearLocalStorage);
</script>
<style lang="scss">
@import url("@/assets/scss/style.scss");
@import url("@/assets/scss/animation.scss");
@import url("@/assets/scss/media.scss");
</style>
