<template>
  <div class="v-swiper">
    <div class="v-swiper__body d-flex justify-center">
      <div
        class="v-swiper__item"
        v-for="(i, id) in items"
        :key="i"
        @click="setActive(id)"
        style="cursor: pointer;"
        :class="{
          'active': active == id,
          'closed': isClosed,
          'anim': !isClosed,
          'left': active + 1 == id || (active == 2 && id == 0),
          'right': active - 1 == id || (active == 0 && id == 2),
        }"
      >
        <div class="top jetbrains text-l d-flex justify-between align-center">
          <p class="slider">{{ i.title }}</p>
          <div class="icon d-flex justify-center align-center">
            <img v-if="id == 2" src="@/assets/icons/disk.png" alt="img" />
            <img v-if="id == 0" src="@/assets/icons/human.png" alt="img" />
            <img v-if="id == 1" src="@/assets/icons/cloud.png" alt="img" />
          </div>
        </div>
        <p class="text helvetica slider" :class="active == id ? 'text-s' : 'text-xs'">
          {{ i.text }}
        </p>
        <RouterLink :to="i.link">
          <Button
            class="slider"
            colorPrime
            :icon="id == 1 ? 'cloud' : id == 0 ? 'vision' : 'db'"
          >
            {{ i.btn }}
          </Button>
        </RouterLink>
      </div>
    </div>
    <div class="loader d-flex">
      <div
        class="loader__item"
        v-for="i in 3"
        :key="i"
        @click="isFilled = false; active = i - 1"
      >
        <div
          class="fill"
          :class="{'anim': active + 1 == i, 'filled' : isFilled[i-1]}"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import Button from "./Button.vue";

const items = ref([
  {
    title: "Software Development",
    text:
      "Crafting bespoke software tailored to your business goals. Whether it's web applications, mobile apps, or enterprise-level systems, our team possesses the expertise to turn your vision into reality.",
    btn: "Craft your Vision",
    link: "/software_development",
  },
  {
    title: "Cloud Solutions",
    text:
      "Navigate the cloud with confidence. From migration and integration to management and optimization, our cloud solutions pave the way for business flexibility, cost efficiency, and innovation.",
    btn: "Elevate to the Cloud",
    link: "cloud_solutions",
  },
  {
    title: "Database Management",
    text:
      "Harness the power of organized data. Our services include database design, optimization, security, and maintenance, ensuring your data remains accessible, secure, and scalable.",
    btn: "Organize your Data",
    link: "database_management",
  },
]);

const active = ref(0);
const isFilled = ref([false, false, false]);
const isClicked = ref(false);
const isClosed = ref(false);
const isChanged = ref(false);
let interval;

const setActive = (id) => {
  clearInterval(interval);
  isClosed.value = true;
  isClicked.value = true;
  // setTimeout(() => {
    isClosed.value = false;
    active.value = id;
    for (let i = 0; i < 3; i++) {
      isFilled.value[i] = true;
      if (id == i) {
        isFilled.value[i] = false;
      }
      if (i > id) isFilled.value[i] = false;
    }
    isClicked.value = false;
    isChanged.value = true; 
    startAutoSlide();
  // }, 1000);
};

const change = () => {
  isClosed.value = false;
  // setTimeout(() => {
    isFilled.value[active.value] = true;
    if (active.value == 2) {
      active.value = 0;
      isFilled.value = [false, false, false];
    } else active.value += 1;
    isClicked.value = false;
    isChanged.value = true;
  // }, 4000);
};

const startAutoSlide = () => {
  interval = setInterval(change, 3500);
};

onMounted(() => {
  startAutoSlide();
});

onBeforeUnmount(() => {
  clearInterval(interval);
});
</script>

<style lang="scss" scoped>
.v-swiper {
  position: relative;
  margin: 64px 0 0;
  &__body {
    max-height: 496px;
    min-height: 496px;
  }
  &__item {
    border-radius: 26.58px;
    opacity: 0.8;
    background: #FFF;
    padding: 26px;
    width: 408px;
    position: absolute;
    &.active {
      border-radius: 32px;
      opacity: 1;
      width: 490px;
      padding: 32px;
      position: relative;
      z-index: 5;
      transform: scale(0.9);
      .text {
        margin: 80px 0 32px;
        width: 84%;
      }
      &.anim {
        animation: circle-anim 0.4s forwards;
      }
      &.closed {
        animation: hide-slow 0.2s forwards;
        animation-delay: 0.1s;
      }
    }
    &.left.anim {
      animation: left-anim 1.4s forwards;
    }
    &.right.anim {
      animation: right-anim 1.4s forwards;
    }
    &.closed:not(.active) {
      animation: close-side 0.2s linear;
      animation-delay: 0.2s;
    }
    .top {
      gap: 32px;
    }
    .icon {
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      background: #F8F8F8;
      padding: 18px;
      align-self: center;
    }
    .text {
      margin: 65px 0 25px;
      width: 80%;
    }
  }
}
.loader {
  gap: 16px;
  margin-top: 77px;
  &__item {
    width: 100%;
    position: relative;
    height: 2px;
    background: rgba(255, 255, 255, 0.2);
    & .fill {
      height: 2px;
      background: #C5D86D;
      position: absolute;
      top: 0;
      left: 0;
      &.anim {
        animation: line 3s 1 forwards;
      }
      &.filled {
        width: 100%;
      }
    }
  }
}

@keyframes left-anim {
  0% {
    opacity: 0;
    transform: rotate(-10deg) translate3d(0, 0, 0);
  }
  70% {
    opacity: 0.8;
    transform: rotate(-8deg) translate3d(-75%, 0, 0);
  }
  100% {
    opacity: 0.8;
    transform: rotate(-8deg) translate3d(-74%, 0, 0);
  }
}

@keyframes right-anim {
  0% {
    opacity: 0;
    transform: rotate(10deg) translate3d(0, 0, 0);
  }
  70% {
    opacity: 0.8;
    transform: rotate(8deg) translate3d(75%, 0, 0);
  }
  100% {
    transform: rotate(8deg) translate3d(74%, 0, 0);
    opacity: 0.8;
  }
}

@keyframes close-side {
  40% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: rotate(0) translate3d(0, 0, 0);
  }
}

@keyframes left2-anim {
  0% {
    opacity: 0;
  }
  100% {
    left: -46%;
    opacity: 0.8;
  }
}

@keyframes right2-anim {
  0% {
    opacity: 0;
  }
  100% {
    right: -46%;
    opacity: 0.8;
  }
}

@keyframes left3-anim {
  0% {
    opacity: 0;
  }
  100% {
    left: -28%;
    opacity: 0.8;
  }
}

@keyframes right3-anim {
  0% {
    opacity: 0;
  }
  100% {
    right: -28%;
    opacity: 0.8;
  }
}

@media (max-width: 1200px) {
  .v-swiper {
    &__body {
      min-height: 0;
      max-height: none;
      overflow-x: visible;
    }
    &__item {
      &.active{
        min-height: 430px;
        .text {
          width: 77%;
        }
      }
      &.right, &.left {
        .text {
          width: 85%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .v-swiper {
    margin: 66px 0 0;
    &__item {
      .icon {
        padding: 13px 14px 15px 14px;
      }
      &.active, &.left, &.right {
        width: 85%;
        max-width: 500px;
        .text {
          width: 80%;
          margin: 60px 0 32px;
        }
      }
      &.left, &.right {
        .top p {
          max-width: 120px;
        }
        .text {
          overflow: hidden;
          max-height: 120px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .v-swiper {
    &__item {
      width: 95%;
      max-width: 350px;
      &.left, &.right {
        top: 30px;
        .text {
          width: 100%;
          overflow: hidden;
          max-height: 150px;
          margin:32px 0 24px;
        }
      }
      &.active {
        width: 95%;
        max-width: 350px;
        padding: 26px;
        .text {
          width: 100%;
          margin:34px 0 26px;
        }
      }
    }
  } 
    .loader {
        margin: 115px 2px 0 !important;
    }
}
</style>