<template>
    <div class="ui-modal">
        <div class="ui-modal__wrap" @click="$emit('close')"></div>
        <div class="ui-modal__content">
            <div class="body">
                <svg @click="$emit('close')" class="cross" style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M6 18L18 6M6 6L18 18" stroke="#1B1A21" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="title text-m uppercase futura-bold color-dark">{{called ? success ? 'success!' : 'oops!' : isSent ? isSuccess ? 'success!' : 'oops!' : 'contact us' }}</p>
                <form v-if="!isSent && !called" id="formm">
                    <div class="d-flex inputs">
                        <div class="d-flex align-center input" :class="{'error': isError && data.name.toString().trim().length == 0}">
                            <input
                                class="jetbrains"
                                type="text" 
                                placeholder="Name" 
                                @input="handleInput($event, 'name')" 
                                v-model="data.name" 
                                required 
                                aria-required="true" 
                                maxlength="100"
                            />
                            <svg v-if="isError && data.name.toString().trim().length == 0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" fill="#E53737"/>
                            </svg>
                        </div>
                        <div class="input d-flex align-center" :class="{'error': isError && isError && !emailRegex.test(data.email)}">
                            <input
                                class="jetbrains"
                                type="email" 
                                placeholder="Email"
                                v-model="data.email"
                                required
                                maxlength="250"
                            />
                            <svg v-if="isError && isError && !emailRegex.test(data.email)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" fill="#E53737"/>
                            </svg>
                        </div>
                        
                    </div>
                    <textarea class="mid-input input jetbrains" placeholder="Message" v-model="data.message"></textarea>
                    <label class="custom-checkbox d-flex" for="ch-1" @click="isChecked = !isChecked" :key="isChecked">
                        <input type="checkbox" id="ch-1" :checked="isChecked" required name="terms"/>
                        <span class="checkmark" :class="{'hoverable': !isChecked}"></span>
                        <span class="helvetica">I have read and accept the <router-link to="/privacy">Privacy Policy</router-link>.</span>
                    </label>
                    <Button icon="jet" colorPrime @click="send">Send your Message</Button>
                    <Button icon="" @click="$emit('close')">Cancel</Button>
                </form>

                <div v-else="isSent || called" class="d-flex column result-block">
                    <svg v-if="isSuccess || success" xmlns="http://www.w3.org/2000/svg" width="111" height="110" viewBox="0 0 111 110" fill="none">
                        <rect x="0.5" width="110" height="110" rx="55" fill="#4169E1"/>
                        <path d="M33.501 59.125L50.001 75.625L74.751 38.5" stroke="white" stroke-width="8.25" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg v-if="!isSuccess && !success" xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
                        <path d="M45 33.75V47.8125M78.75 45C78.75 49.4321 77.877 53.8208 76.1809 57.9156C74.4848 62.0103 71.9988 65.7309 68.8649 68.8649C65.7309 71.9988 62.0103 74.4848 57.9156 76.1809C53.8208 77.877 49.4321 78.75 45 78.75C40.5679 78.75 36.1792 77.877 32.0844 76.1809C27.9897 74.4848 24.2691 71.9988 21.1351 68.8649C18.0012 65.7309 15.5152 62.0103 13.8191 57.9156C12.123 53.8208 11.25 49.4321 11.25 45C11.25 36.0489 14.8058 27.4645 21.1351 21.1351C27.4645 14.8058 36.0489 11.25 45 11.25C53.9511 11.25 62.5355 14.8058 68.8649 21.1351C75.1942 27.4645 78.75 36.0489 78.75 45ZM45 59.0625H45.03V59.0925H45V59.0625Z" stroke="white" stroke-width="5.625" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <p class="helvetica text-xs color-dark">{{ isSuccess || success ? 'Your application has been accepted. You will be contacted shortly for further details' : 'Something went wrong. Please try again!' }}</p>
                    <Button icon="" color-prime @click="$emit('close')">Close</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, watch, onBeforeUnmount, onUnmounted, onMounted} from "vue";
import Button from "./Button.vue";

const data = ref({
    name: "",
    email: "",
    message: ""
})

const props = defineProps({
    isOpen: Boolean,
    called: Boolean,
    success: Boolean
})

const isError = ref(false);
const isChecked = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const isSent = ref(false);
const isSuccess = ref(false);

const validate = () => {
    let error = false;
    Object.entries(data.value).map(([key, value]) => {
        if(key == 'name' && value.toString().trim().length == 0) error = true;
        if(key === 'email' && !emailRegex.value.test(value)) error = true;
    });
    return error;
}
const handleInput = (event,propertyName) => {
    data.value[propertyName] = event.target.value;
    if(isError.value) validate();
}
const resetForm = () => {
    data.value = {
        name: "",
        email: "",
        message: ""
    }
    isChecked.value = false;
}
const fetchData = (formdata) => {
    const request = {
        method: 'POST',
        body: formdata,
        mode: 'no-cors',
    }

    fetch('https://license.darmius.kz/mailsend', request)
    .then(response => {
        isSent.value = true;
        isSuccess.value = true;
        resetForm();
    })
    .catch(e => {
        isSent.value = true;
        isSuccess.value = false;
        resetForm();
    })
}

const send = () => {
    let form = document.getElementById('formm');
    isError.value = validate();
    form.reportValidity();
    if(!isError.value && isChecked.value) {
        const formdata = new FormData();
        formdata.append('id', 'falkoned');
        formdata.append('name', data.value.name);
        formdata.append('email', data.value.email);
        formdata.append('emailto', "info@lfinspire.com");
        formdata.append('message', data.value.message);

        fetchData(formdata)
    }
}

onMounted(() => {
    document.body.classList.add('no-scroll');
})
onBeforeUnmount(() => {
    resetForm();
    document.body.classList.remove('no-scroll');
})
onUnmounted(() =>{
    resetForm();
    isSent.value = false;
    isSuccess.value = false;
    document.body.classList.remove('no-scroll');
})

</script>

<style lang="scss" scoped>
.ui-modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    &__wrap {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(27, 26, 33, 0.88);
    }
    &__content {
        max-width: 550px;
        border-radius: 20px;
        background: #FFF;
        padding: 48px;
        position: relative;
        z-index: 11;
        .body{
            position: relative;
            width: 100%;
            .cross {
                position: absolute;
                right: 0;
                top: -10px;
                transition: all 0.3s ease;
                &:hover {
                    path {
                        stroke: #4169E1;
                    }
                }
            }
            .title {
                margin-bottom: 38px;
            }
        }
    }
}
form {
    .inputs{
        gap: 16px;
    }
    textarea, .custom-checkbox + .button {
        margin: 16px 0;
    }
    .button {
        width: 100%;
        & + .button {
            background: rgba(0, 0, 0, 0.06);
            color:#000;
            &:hover {
                background: rgba(0, 0, 0, 0.12);
            }
        }
    }
    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        height: 20px;
        width: 10px;
    }
}
.result-block {
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    min-width: 469px;
    .button {
        width: 100%;
    }
    p {
        max-width: 278px;
        margin: 0 auto;
        text-align: center;
    }
}
.custom-checkbox {
  font-size: 14px;
  line-height: 22px;

  .helvetica {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.5;
  }

  & span:nth-child(3) {
    padding-left: 12px;
    color: #1B1A21 !important;
    opacity: 0.5;
    width: 75%;
    transition: opacity 0.3s ease;
  }  
  &:hover {
    cursor: pointer;
    .checkmark.hoverable {
        box-shadow: 0 0 0 1.5px rgba(0, 0, 0, 0.16);
    }
    input[type="checkbox"]:checked + .checkmark {
        background-color: #6B8BED;
        box-shadow: 0 0 0 0.5px #6B8BED;
    }
    & span:nth-child(3) {
        opacity: 0.72;
    }
  }
  & span > span {
    text-decoration: underline;
  }
  .checkmark {
    display: inline-block;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border-radius: 6px;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.16);
    background: #FFF;
    backdrop-filter: blur(13px);
    transition: box-shadow, background-color 0.3s ease;
    cursor: pointer;
    transition: border .3s ease;
  }
  input[type="checkbox"]:checked + .checkmark {
    background-size: 75% 75%;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #4169E1;
    box-shadow: 0 0 0 0.5px #4169E1;
    background-image: url("@/assets/icons/check.svg");
  }
  input[type="checkbox"]:checked + .checkmark::before {
    display: block;
  }
  a, a:visited {
    text-decoration: none;
    color: #1B1A21 !important;
  }
}

@media (max-width: 768px) {
    .ui-modal {
        &__content {
            width: 100%;
            margin: auto 20px;
            padding: 32px;
        }
    }
    .result-block {
        min-width: 0;
    }
    form {
        .inputs {
            flex-direction: column;
        }
    }
}
</style>