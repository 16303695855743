<template>
    <div class="anim-cover d-flex">
        <div class="block1"></div>
        <div class="block2"></div>
        <div class="loader-content d-flex column align-center justify-center">
            <div class="logo-wrap">
                <img src="@/assets/icons/anim_logo.svg" />
            </div>
            <div class="line">
                <div class="line-cover"></div>
                <div class="line-gray"></div>
            </div>
        </div>  
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.anim-cover {
    position: fixed;
    width: calc(100% + 180px);
    height: 100%;
    top: 0;
    left: -180px;
    background: #F8F8F8;
    z-index: 10;
    animation: goright 1s forwards;
    animation-delay: 1.5s;
}
.anim-cover .loader-content{
    width: 100vw;
    height: 100%;
}
.block1, .block2 {
    width: 90px;
    opacity: 0.8;
    height: 100%;
    animation: smash 0.8s forwards;
    animation-delay: 2s;
}
.block2 {
    background: #4169E1;
}
.block1 {
    background: #C5D86D;
}
.logo-wrap {
    height: 60px;
    img {
        height: 100%;
    }
}
.line {
    position: relative;
    max-width: 500px;
    height: 3px;
    width: 100%;
    margin-top: 40px;
    &-gray {
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.15;
    }
    &-cover {
        background: #4169E1;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-animation: line 1.5s 1 forwards;
        -moz-animation: line 1.5s 1 forwards;
        -o-animation: line 1.5s 1 forwards;
        animation: line 1.5s 1 forwards;
    }
}

@keyframes goright {
    to {
        left: 100%;
    }
}
@keyframes smash {
    to {
        width: 0;
    }
}

@media (max-width: 768px) {
    .block1, .block2 {
        width: 60px;
    }
    .anim-cover {
        width: calc(100% + 120px);
        left: -120px;
    }
    .line {
        width: 80%;
        max-width: 300px;
    }
    .logo-wrap {
        height: 40px;
    }
}
</style>